<template>
  <page-container :title="pageTitle" :breadcrumb="breadcrumb">
    <div class="box-container" style="margin-top: 10px;">
        <div v-show="showAdvanced" class="advanced-query">
        </div>
      </div>
    <div class="box-container" style="margin-top: 10px;">
      <div class="box-container-inner">
        <a-tabs default-active-key="1" :active-key="tabskey" @change="callback">
          <a-tab-pane key="1" tab="巡查方案">
            <div class="table-header">
              <h3 class="table-title">{{pageTitle}}</h3>
              <div class="table-btns">
                <a-button type="primary" @click="showModal()"><a-icon type="plus"></a-icon>新增</a-button>
                <a-icon type="redo" class="after-btn table-refresh" @click="getTableData(true)"></a-icon>
              </div>
            </div>
            <a-table :columns="tableColumns" :data-source="tableData" :rowKey="(record,index)=> index" :loading="tableLoading" :row-class-name="(record, index) => (index%2==0?'table-row-even':'table-row-single')" style="margin-top: 20px;" :pagination="false">
              <span slot="patrolname" slot-scope="text,record">
                <a-button type="link" @click="rowClick(record)">{{text}}</a-button>
              </span>
              <span slot="createtime" slot-scope="value">
                <a-tooltip :title="value ? moment(value, 'YYYYMMDDHHmmss').format('YYYY-MM-DD HH:mm:ss') : ''">
                  <span>{{value ? moment(value, 'YYYYMMDDHHmmss').format('YYYY-MM-DD HH:mm:ss') : ''}}</span>
                </a-tooltip>
              </span>
              <span slot="patrolshift" slot-scope="value">
                <span>{{patrolShifts[value].value}}</span>
              </span>
              <span slot="template" slot-scope="value,record">
                <a-button type="link" @click="productTmeplate(value,record)">生成</a-button>
              </span>
              <span slot="operation" slot-scope="value, record">
            <a-dropdown>
              <a class="ant-dropdown-link" @click="e => e.preventDefault()">
                <a-icon type="menu"/>
              </a>
              <a-menu slot="overlay" @click="operationClick">
<!--                <a-menu-item :key="'add-'+record.patrolschemeid">新增</a-menu-item>-->
                <a-menu-item :key="'detail-'+record.patrolschemeid">详情</a-menu-item>
                <a-menu-item :key="'edit-'+record.patrolschemeid">修改</a-menu-item>
                <a-menu-item :key="'delete-'+record.patrolschemeid">删除</a-menu-item>
              </a-menu>
            </a-dropdown>
          </span>
            </a-table>
            <a-pagination v-model="currentPage" :total="pagination.total" :page-size="pagination.pageSize" show-size-changer @change="pageChange" @showSizeChange="onShowSizeChange" :show-total="(total, range) => `共${total}条`" style="margin-top: 20px; text-align: right;">
              <template slot="buildOptionText" slot-scope="props">
                <span>{{ props.value }}条/页</span>
              </template>
            </a-pagination>
          </a-tab-pane>
          <a-tab-pane key="2" tab="巡查模板" force-render>
            <div class="table-header">
              <h3 class="table-title">巡查模板</h3>
              <div class="table-btns">
<!--                <a-button type="primary" @click="showModal()"><a-icon type="plus"></a-icon>新增</a-button>-->
                <a-icon type="redo" class="after-btn table-refresh" @click="getTableData(true)"></a-icon>
              </div>
            </div>
            <a-table :columns="templateColumns" :data-source="templateData" :rowKey="(record,index)=> index" :loading="tableLoading" :row-class-name="(record, index) => (index%2==0?'table-row-even':'table-row-single')" style="margin-top: 20px;" :pagination="false">
              <span slot="patroltemplatename" slot-scope="text,record">
                <a-button type="link" @click="rowClick1(record)">{{text}}</a-button>
              </span>
              <span slot="createtime" slot-scope="value">
                <a-tooltip :title="value ? moment(value, 'YYYYMMDDHHmmss').format('YYYY-MM-DD HH:mm:ss') : ''">
                  <span>{{value ? moment(value, 'YYYYMMDDHHmmss').format('YYYY-MM-DD HH:mm:ss') : ''}}</span>
                </a-tooltip>
              </span>
              <span slot="patrolshift" slot-scope="value">
                <span>{{patrolShifts[value-1].value}}</span>
              </span>
              <span slot="intervals" slot-scope="value, record">
                <span>{{intervals[value].value}}</span>
              </span>
              <span slot="operation" slot-scope="value, record">
              <a-dropdown>
              <a class="ant-dropdown-link" @click="e => e.preventDefault()">
                <a-icon type="menu"/>
              </a>
              <a-menu slot="overlay" @click="operationClick1">
<!--                <a-menu-item :key="'add-'+record.patrolschemeid">新增</a-menu-item>-->
                <a-menu-item :key="'detail-'+record.patroltemplateid">详情</a-menu-item>
<!--                <a-menu-item :key="'edit-'+record.patrolschemeid">修改</a-menu-item>-->
                <a-menu-item :key="'delete-'+record.patroltemplateid">删除</a-menu-item>
              </a-menu>
            </a-dropdown>
          </span>
            </a-table>
            <a-pagination v-model="currentPage1" :total="pagination1.total" :page-size="pagination1.pageSize" show-size-changer @change="pageChange1" @showSizeChange="onShowSizeChange1" :show-total="(total, range) => `共${total}条`" style="margin-top: 20px; text-align: right;">
              <template slot="buildOptionText" slot-scope="props">
                <span>{{ props.value }}条/页</span>
              </template>
            </a-pagination>
          </a-tab-pane>
          <a-tab-pane key="3" tab="人工巡查线路" force-render>
            <artificial-patrol></artificial-patrol>
          </a-tab-pane>
        </a-tabs>
      </div>
    </div>
    <a-modal v-model="templateVisible" title="生成巡查模板" width="450px">
      <template slot="footer">
        <a-button @click="templateVisible=false">取消</a-button>
        <a-button type="primary" @click="handleOk">提交</a-button>
      </template>
      <a-form-model ref="advancedForm" :model="queryParams" :rules="formRules" layout="inline" class="query-form" :label-col="{span:10}" :wrapper-col="{span:14}">
<!--        <a-form-model-item label="巡查模板名" prop="patroltemplatename">-->
<!--          <a-input v-model="queryParams.patroltemplatename"></a-input>-->
<!--        </a-form-model-item>-->
        <sapn style="color:red;">提示：请在选择班次时注意，如果为两班制须选择白班和夜班，如果为三班制则选择早中晚班</sapn>
        <a-form-model-item label="工作班制" prop="shiftSystem">
          <a-select v-model.trim="queryParams.shiftSystem" placeholder="请输入" style="width: 220px">
            <a-select-option :value="1">两班制</a-select-option>
            <a-select-option :value="2">三班制</a-select-option>
          </a-select>
        </a-form-model-item>
        <a-form-model-item label="巡查班次" prop="patrolshift">
          <a-select v-model.trim="queryParams.patrolshift" placeholder="请输入" style="width: 220px">
            <a-select-option v-if="queryParams.shiftSystem===2" :value="1">早班</a-select-option>
            <a-select-option v-if="queryParams.shiftSystem===2" :value="2">中班</a-select-option>
            <a-select-option v-if="queryParams.shiftSystem===2" :value="3">晚班</a-select-option>
            <a-select-option v-if="queryParams.shiftSystem===1" :value="4">白班</a-select-option>
            <a-select-option v-if="queryParams.shiftSystem===1" :value="5">夜班</a-select-option>
          </a-select>
        </a-form-model-item>
        <a-form-model-item label="巡查时间段" prop="timerange"  style="margin-left: -30px">
          <div style="display: flex;margin-top: 4px">
            <a-time-picker v-model="start" format="HH:mm" style="width: 140px"/>
            <a-time-picker v-model="end" format="HH:mm" style="width: 140px"/>
<!--            <a-select style="width: 100px" prop="start" v-model="start">-->
<!--              <a-select-option v-for="item in timeList" :key="item.key">-->
<!--                {{ item.value }}-->
<!--              </a-select-option>-->
<!--            </a-select>-->
<!--            <a-select style="width: 100px" prop="end" v-model="end">-->
<!--              <a-select-option v-for="item in timeList" :key="item.key">-->
<!--                {{ item.value }}-->
<!--              </a-select-option>-->
<!--            </a-select>-->
          </div>
        </a-form-model-item>
        <a-form-model-item label="巡查时间间隔" prop="interval"  style="margin-left: -5px">
          <a-select v-model.trim="queryParams.intervals" placeholder="请输入" style="width: 220px">
            <a-select-option v-for="item in intervals" :key="item.key">
              {{ item.value }}
            </a-select-option>
          </a-select>
        </a-form-model-item>
      </a-form-model>
    </a-modal>
  </page-container>
</template>
<script>
import { getItemFromArrayByKey } from 'U'
import { mapState } from 'vuex'
import moment from 'moment'
import pagination from '@/mixins/pagination'
import artificialPatrol from '../artificialPatrol/Index.vue'
import {
  addPatrolTemplate,
  getPatrolSchemeDataListByCondition,
  getPatrolSchemeListByCondition,
  getPatrolTemplateListByCondition, modifyPatrolScheme, modifyPatrolTemplate
} from "A/patrol";
import {patrolShifts, timeList} from "@/json/wlwhistory";
export default {
  name: 'patrolSetting',
  mixins: [pagination],
  props: ['pageType'],
  components:{
    artificialPatrol,
  },
  data() {
    return {
      moment,
      timeList,
      patrolShifts,
      tabskey:'1',
      showAdvanced: false,
      templateVisible:false,
      intervals:[
        {"key":0,"value":"一小时一次"},
        {"key":1,"value":"两小时一次"},
        {"key":2,"value":"四小时一次"}
      ],
      queryParams: {
        patrolschemeid:'',
        monitorpointname:'',
        monitorpointnum:'',
        patroltemplatename:'',
        patrolshift:'',
        shiftSystem:'',
        timerange:'',
        intervals:'',
        recordman:'',
      },
      formRules: {
        shiftSystem: [{required: true, message: '请选择巡查班制'}],
        patrolshift: [{required: true, message: '请选择巡查班次'}],
        timerange: [{required: true, message: '请输入巡查时间段'}],
        intervals: [{required: true, message: '请选择巡查时间间隔'}],
      },
      start:'',
      end:'',
      tableColumns: [
        {
          title: '巡查方案名',
          dataIndex: 'patrolname',
          key: 'patrolname',
          ellipsis: true,
          scopedSlots: { customRender: 'patrolname' },
        },
        {
          title: '项目名称',
          dataIndex: 'monitorpointname',
          key: 'monitorpointname',
          ellipsis: true,
        },
        {
          title: '地址',
          dataIndex: 'address',
          key: 'address',
          ellipsis: true,
        },
        {
          title: '设置人',
          dataIndex: 'recordman',
          key: 'recordman',
          ellipsis: true,
          width: 100,
        },
        {
          title: '设置时间',
          dataIndex: 'createtime',
          key: 'createtime',
          ellipsis: true,
          width: 140,
          scopedSlots: { customRender: 'createtime' },
        },
        {
          title: '巡查模板',
          key: 'template',
          width: 70,
          scopedSlots: { customRender: 'template' }
        },
        {
          title: '操作',
          key: 'operation',
          align: 'center',
          width: 70,
          scopedSlots: { customRender: 'operation' }
        },
      ],
      tableData: [],
      tableLoading: false,
      modalVisible: false,
      modalShowType: '',
      modalDetailData: null,
      schemeData:[],
      templateColumns: [
        {
          title: '巡查模板名',
          dataIndex: 'patroltemplatename',
          key: 'patroltemplatename',
          ellipsis: true,
          scopedSlots: { customRender: 'patroltemplatename' },
        },
        {
          title: '班次',
          dataIndex: 'patrolshift',
          key: 'patrolshift',
          ellipsis: true,
          width: 50,
          scopedSlots: { customRender: 'patrolshift' },
        },
        {
          title: '巡查模板时段',
          dataIndex: 'timerange',
          key: 'timerange',
          ellipsis: true,
          width: 100,
          scopedSlots: { customRender: 'timerange' },
        },
        {
          title: '巡查时间间隔',
          dataIndex: 'intervals',
          key: 'intervals',
          ellipsis: true,
          width: 100,
          scopedSlots: { customRender: 'intervals' },
        },
        {
          title: '项目名称',
          dataIndex: 'monitorpointname',
          key: 'monitorpointname',
          ellipsis: true,
        },
        {
          title: '地址',
          dataIndex: 'address',
          key: 'address',
          ellipsis: true,
        },
        {
          title: '设置人',
          dataIndex: 'recordman',
          key: 'recordman',
          ellipsis: true,
          width: 100,
        },
        {
          title: '设置时间',
          dataIndex: 'createtime',
          key: 'createtime',
          ellipsis: true,
          width: 140,
          scopedSlots: { customRender: 'createtime' },
        },
        {
          title: '操作',
          key: 'operation',
          align: 'center',
          width: 70,
          scopedSlots: { customRender: 'operation' }
        },
      ],
      templateData:[],
    }
  },
  computed: {
    ...mapState({
      userInfo: state => state.userInfo
    }),
    pageTitle() {
      return '巡查方案';
    },
    breadcrumb() {
      return [
        {
          name: '项目设置',
          path: ''
        },
        {
          name: this.pageTitle,
          path: ''
        },
      ]
    },
  },
  watch: {},
  created() {
    if(this.$route.params.tabskey){
      this.tabskey=this.$route.params.tabskey
    }
    this.init();
  },
  methods: {
    init() {
      this.getTableData(true);
    },
    resetQueryParams() {
      this.$refs.queryForm.resetFields();
    },
    getTableData(firstPage) {
      if(firstPage) {
        this.currentPage = 1;
      }
      this.tableLoading = true;
      let params = {
        status:0,
        pageno: this.currentPage,
        pagesize: this.pagination.pageSize
      };
      getPatrolSchemeListByCondition(params).then(res => {
        this.tableLoading = false;
        if(res && res.returncode == '0') {
          this.tableData = res.item;
          this.pagination.total = res.count;
        }
      }).catch(err => {
        this.tableLoading = false;
      })
      let params1 = {
        status:0,
        pageno: this.currentPage1,
        pagesize: this.pagination1.pageSize
      };
      getPatrolTemplateListByCondition(params1).then(res => {
        this.tableLoading = false;
        if(res && res.returncode == '0') {
          this.templateData = res.item;
          for(let i=0;i<this.templateData.length;i++){
            this.templateData[i].timerange=moment.unix(this.templateData[i].timerange.split("-")[0]/1000).format("HH:mm")+"-"+moment.unix(this.templateData[i].timerange.split("-")[1]/1000).format("HH:mm")
          }
          this.pagination1.total = res.count;
        }
      }).catch(err => {
        this.tableLoading = false;
      })
    },
    callback(key){
      this.tabskey=key
    },
    showModal() {
      this.$router.push({
        name: 'patrolScheme',
        params: {
          showType:'edit',
          modalTitle:"项目设置"
        }
      })
    },
    operationClick({key}) {
      let arr = key.split('-');
      let type = arr[0];
      let patrolschemeid = arr[1];
      let record = getItemFromArrayByKey(this.tableData, 'patrolschemeid', patrolschemeid);
      if(type == 'delete') {
        this.deleteConfirm(patrolschemeid, record)
      }else if(type == 'detail'||type == 'edit'){
        getPatrolSchemeDataListByCondition({patrolschemeid:record.patrolschemeid}).then(res=>{
          if(res&&res.returncode=='0'){
            this.schemeData=res.item
            this.$router.push({
              name: 'patrolScheme',
              params: {
                scheme: record,
                schemeData:this.schemeData,
                showType:type,
                modalTitle:"项目设置"
              }
            })
          }
        })
      }
    },
    deleteConfirm(value, record) {
      this.$confirm('确定要删除该数据？',{
        type:'warning',
        centered:true,
      }).then(()=>{
        this.delete(record.patrolschemeid);
      }).catch(()=>{
      });
    },
    delete(patrolschemeid) {
      this.showLoading();
      if(patrolschemeid) {
        let params = {
          patrolschemeid,
          status:1
        };
        modifyPatrolScheme(params).then(res => {
          this.hideLoading();
          if(res && res.returncode == '0') {
            this.$message.success('操作成功');
            this.getTableData(true);
          }else {
            this.$message.error(res.errormsg||'操作失败');
          }
        })
      }
    },
    rowClick(record) {
      getPatrolSchemeDataListByCondition({patrolschemeid:record.patrolschemeid}).then(res=>{
        if(res&&res.returncode=='0'){
          this.schemeData=res.item
          this.$router.push({
            name: 'patrolScheme',
            params: {
              scheme: record,
              schemeData:this.schemeData,
              showType:'detail',
              modalTitle:"项目设置"
            }
          })
        }
      })
    },
    productTmeplate(value,record){
      this.templateVisible=true
      this.queryParams.monitorpointnum=record.monitorpointnum
      this.queryParams.monitorpointname=record.monitorpointname
      this.queryParams.patrolschemeid=record.patrolschemeid
      this.queryParams.patrolshift=null
      this.start=null
      this.end=null
    },
    handleOk(){
      if(this.start.toString().length>0&&this.end.toString().length>0){
        this.queryParams.timerange=this.start+"-"+this.end
      }
      this.queryParams.patroltemplatename=this.queryParams.monitorpointname+this.patrolShifts[this.queryParams.patrolshift-1].value
      this.queryParams.recordman=this.userInfo.username
      let params = {
        ...this.queryParams
      }
      addPatrolTemplate(params).then(res=>{
        if(res&&res.returncode=='0'){
          this.templateVisible=false
          this.$message.success("操作成功")
          this.getTableData(true)
          this.tabskey='2'
        }else{
          this.$message.error(res.errormsg)
        }
      })
    },
    showModal1(monitorpointnum,patrolshift) {
      this.$router.push({
        name: 'patrolTemplate',
        params: {
          monitorpointnum:monitorpointnum,
          patrolshift:patrolshift,
          patrolrecordid: "",
          showType:'detail',
          modalTitle:"项目设置"
        }
      })
    },
    operationClick1({key}) {
      let arr = key.split('-');
      let type = arr[0];
      let patroltemplateid = arr[1];
      let record = getItemFromArrayByKey(this.templateData, 'patroltemplateid', patroltemplateid);
      if(type == 'delete') {
        this.deleteConfirm1(patroltemplateid, record)
      }else if(type == 'detail'){
        this.showModal1(record.monitorpointnum,record.patrolshift)
      }
    },
    deleteConfirm1(value, record) {
      this.$confirm('确定要删除该数据？',{
        type:'warning',
        centered:true,
      }).then(()=>{
        this.delete1(record.patroltemplateid,record.monitorpointnum);
      }).catch(()=>{
      });
    },
    delete1(patroltemplateid,monitorpointnum) {
      this.showLoading();
      if(patroltemplateid) {
        let params = {
          patroltemplateid,
          status:1
        };
        modifyPatrolTemplate(params).then(res => {
          this.hideLoading();
          if(res && res.returncode == '0') {
            this.$message.success('操作成功');
            this.getTableData(true);
          }else {
            this.$message.error(res.errormsg||'操作失败');
          }
        })
        // getPatrolTemplateListByCondition({monitorpointnum:monitorpointnum,status:1}).then(res=>{
        //   if(res && res.returncode == '0') {
        //     if(res.item.length>0){
        //       deletePatrolTemplate(params).then(res => {
        //         this.hideLoading();
        //         if(res && res.returncode == '0') {
        //           this.$message.success('操作成功');
        //           this.getTableData();
        //         }else {
        //           this.$message.error(res.errormsg||'操作失败');
        //         }
        //       })
        //     }else{
        //       modifyPatrolTemplate(params).then(res => {
        //         this.hideLoading();
        //         if(res && res.returncode == '0') {
        //           this.$message.success('操作成功');
        //           this.getTableData();
        //         }else {
        //           this.$message.error(res.errormsg||'操作失败');
        //         }
        //       })
        //     }
        //   }
        // })
      }
    },
    rowClick1(record) {
      this.showModal1(record.monitorpointnum,record.patrolshift)
    },
  }
}
</script>
<style lang="scss" scoped>
</style>